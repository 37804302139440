import { container, IGetOperatorLogin, ILoginOperator, Symbols } from "@arsenale/core";
import { ActionFunctionArgs, LoaderFunctionArgs } from "@remix-run/node";
import { Form, json, MetaFunction, redirect, useActionData } from "@remix-run/react";
import z from "zod";
import { operator_cookie } from "~/cookies.server";
import getOperatorSessId from "~/shared/utils/getOperatorSessId";


export const meta: MetaFunction = () => {
    return [
        {
            title: "Login - Admiin"
        }
    ];
}

const validator = z.object({
    username: z.string(),
    password: z.string(),
});

export async function loader({ request }: LoaderFunctionArgs) {
    const sessidOrNone = await getOperatorSessId(request);

    if (!sessidOrNone.isSome()) {
        return null;
    }

    const userOrNone = await container.get<IGetOperatorLogin>(Symbols.UseCase.operator_login.GetOperatorLogin)
        .execute({ operator_session_id: sessidOrNone.data });

    if (userOrNone.some) {
        return redirect("/admin/");
    } else {
        return new Response(null, {
            headers: {
                "Set-Cookie": await operator_cookie.serialize("", { maxAge: -1 })
            }
        })
    }
}


export async function action(args: ActionFunctionArgs) {
    const body = validator.safeParse(Object.fromEntries(await args.request.formData()));

    if (!body.success) {
        return json({
            ...body.error.formErrors.fieldErrors,
            form: "",
        });
    }

    const res = await container.get<ILoginOperator>(Symbols.UseCase.operator_login.LoginOperator)
        .execute({
            username: body.data.username,
            password: body.data.password
        });

    if (!res.isSuccess()) {
        return json({
            username: undefined,
            password: undefined,
            form: res.error.message
        });
    }

    const ls = res.data;

    return redirect("/admin/", {
        headers: {
            "Set-Cookie": await operator_cookie.serialize(ls._id, { expires: new Date(ls.expires_at) }),
        }
    });
}

export default function Login() {
    const actionData = useActionData<typeof action>();

    return <div className="hero bg-base-200 min-h-screen">
        <div className="hero-content flex-col lg:flex-row-reverse">
            <div className="card bg-base-100 w-full max-w-sm shrink-0 shadow-2xl">
                <Form method="post" className="card-body">
                    <h1 className="text-2xl font-bold mb-4">Login Operatore</h1>
                    <label className="input input-bordered flex items-center gap-2 mb-4" >
                        <p>Username</p>
                        <input name="username" type="text" className="grow" placeholder="Username" />
                    </label>
                    <span className="text-red-500">{actionData?.username}</span>

                    <label className="input input-bordered flex items-center gap-2 mb-4"  >
                        <p>Password</p>
                        <input name="password" type="password" className="grow" placeholder="••••••" />
                    </label>
                    <span className="text-red-500">{actionData?.password}</span>

                    <span className="text-red-500">{actionData?.form}</span>
                    <div className="flex justify-between items-center">
                        <button type="submit" className="btn btn-primary hover:cursor-pointer w-full">Login</button>
                    </div>
                </Form>
            </div>
        </div>
    </div>
}